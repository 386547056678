@import "/src/assets/styles/mixins.scss";

.orders-list {
  display: flex;
  flex-direction: column;
  &-holder {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &-empty {
    gap: 10px;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
