@import "/src/assets/styles/mixins.scss";

.product {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 64px;
  margin-bottom: 32px;

  &-info {
    min-width: 480px;
    max-width: 480px;
    width: 480px;
    display: flex;
    flex-direction: column-reverse;
    h1 {
      margin-top: 0px;
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 8px;
      @include isMobile {
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;
      }
    }
    .description {
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;

      ul {
        margin-left: 0;
        padding-left: 16px;
      }
      li {
        list-style: disc;
        a {
          color: var(--color-black) !important;
          text-decoration: none;
          &:hover,
          &:active {
            color: var(--color-primary);
          }
        }
      }
      &-list {
        margin-left: 12px;
        a {
          color: var(--color-black) !important;
          text-decoration: none;
          &:hover {
            color: var(--color-primary);
          }
        }
      }
      @include isMobile {
        margin-top: 40px;
      }
    }
    @include isMobile {
      margin-top: 24px;
      margin-left: 0;
      flex-direction: column;
      max-width: unset;
      min-width: unset;
      width: 100%;
      height: 100%;
    }
  }
  &-discount {
    padding: 6px 24px;
    background-color: #f8916c;
    border-radius: 12px;
    color: var(--color-white);
    font-weight: 500;
    font-size: 16px;
    margin-left: 16px;
    margin-bottom: 8px;
  }
  &-price {
    font-weight: 700;
    font-size: 22px;

    @include isMobile {
      font-size: 20px;
    }
  }
  .line-througth {
    margin-left: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    opacity: 0.6;
    text-decoration-line: line-through;
    @include isMobile {
      color: var(--color-red);
      line-height: 22px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  @include isMobile {
    flex-direction: column;
    margin-bottom: 16px;
    gap: 0px;
  }
}

.badge {
  padding: 6px 12px !important;
  border-radius: 16px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  text-transform: capitalize;

  &.kit {
    background: var(--color-black);
    color: var(--color-white);
  }
  &.discount {
    background: var(--color-red);
    color: var(--color-white);
  }
}