.added-to-fav {
  z-index: 2;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  background-color: #ffffff;
  width: 100%;

  &-left {
    gap: 16px;
    display: flex;
    align-items: center;
  }

  &-title {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-black);
  }

  &-subtitle {
    font-size: 12px;
    color: var(--color-black);
  }
}

.set {
  padding: 8px 0;
  gap: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.set-card {
  &-icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: var(--color-bg-light);

    img {
      width: 36px;
      height: 36px;
      object-fit: contain;
    }
  }

  &-title {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-gray-500);
  }

  &-subtitle {
    font-size: 13px;
    color: var(--color-gray-400);
  }
}

.create-set {
  gap: 16px;
  display: flex;
  flex-direction: column;

  &-input {
    padding: 12px 12px;
    border-radius: 8px;
    outline: none;
    border: 1px solid var(--color-gray-200);
    background: var(--color-white);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.02);

    &:focus-visible {
      border: 1px solid var(--color-primary);
    }
  }

  &-btn {
    padding: 16px 32px;
    width: 100%;
    outline: none;
    border: none;
    border-radius: 8px;
    background: var(--color-primary);
    font-size: 14px;
    font-weight: 500;
  }
  &-btn:disabled {
    background: var(--color-gray-100);
    color: var(--color-gray-300);
  }
}

@media screen and (min-width: 1024px) {
  .added-to-fav {
    left: unset;
    right: 24px;
    bottom: 24px;
    min-width: fit-content;
  }

  @keyframes top-up {
    0% {
      bottom: -100px;
    }

    100% {
      bottom: 24px;
    }
  }

  .set {
    width: fit-content;
  }
}
