$colors: (
    primary: #32d9d9,
    light-green: #cfef08,
    black: #151324,
    white: #ffffff,
    gray-500: #464752,
    gray-400: #868688,
    gray-300: #c0c0c3,
    gray-200: #dadce0,
    gray-100: #edeef0,
    gray-50: #f9f9fa,
    red: #eb5555,
    green: #42bf77,
    bg-light: #f7f8fa,
    divider: #edeef0
);

$opacity: (
    8: 0.08,
    10: 0.1,
    20: 0.2,
    50: 0.5,
    80: 0.8
);

@each $color-name, $color-value in $colors {
    .color-#{"" + $color-name} {
        color: #{rgba($color-value, 1)} !important;
    }
    @each $opacity-name, $opacity-value in $opacity {
        .color-#{"" + $color-name}-#{$opacity-name} {
            color: #{rgba($color-value, $opacity-value)} !important;
        }
    }
}

.bg-primary-8 {
    background-color: var(--color-primary-8);
}

.bg-primary {
    background-color: var(--color-primary);
}

.bg-green-8 {
    background-color: var(--color-green-8);
}

.bg-red-8 {
    background-color: var(--color-red-8);
}

.bg-gray-8 {
    background-color: var(--color-gray-400-8);
}

:root {
    @each $color-name, $color-value in $colors {
        --color-#{"" + $color-name}: #{rgba($color-value, 1)};
    }
    @each $color-name, $color-value in $colors {
        @each $opacity-name, $opacity-value in $opacity {
            --color-#{"" + $color-name}-#{"" + $opacity-name}: #{rgba($color-value, $opacity-value)};
        }
    }
}

$fontSizes: 8, 10, 12, 14, 15, 16, 18, 20, 21, 22, 24, 28, 48;

@each $size in $fontSizes {
    .font-#{"" + $size} {
        font-size: #{$size}px !important;
    }
}

$fontWeight: 200, 300, 400, 500, 600, 700, 900;

@each $size in $fontWeight {
    .weight-#{"" + $size} {
        font-weight: #{$size} !important;
    }
}

$aligns: "left", "center", "right";

@each $align in $aligns {
    .text-align-#{"" + $align} {
        text-align: #{$align} !important;
    }
}
