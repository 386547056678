@import "/src/assets/styles/mixins.scss";
@import "/src/assets/styles/shadow.scss";

.search {
    &-holder {
        position: relative;
        width: 100%;
        max-width: 400px;
        @include isMobile {
            max-width: 100%;
            border-radius: 12px;
        }
    }
    &-image {
        width: 12px;
        height: 12px;
    }
    &-results {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-list {
            width: 100%;
            position: absolute;
            top: calc(100% + 8px);
            min-width: 320px;
            left: 0;
            z-index: 1000;
            max-height: 50vh;
            border-radius: 12px;
            background: var(--color-white);
            overflow-y: scroll;
            display: flex;
            align-items: center;
            flex-direction: column;
            @extend .shadow;

            @include isMobile {
                left: -16px;
                top: calc(100% + 9px);
                width: calc(100% + 32px);
                height: calc(100vh - 124px);
                padding-bottom: 60px;
                border-radius: 0;
                box-shadow: none;
                max-height: unset;
            }
        }
        &-item {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            position: relative;
            padding: 16px;
            &-price {
                &-holder {
                    display: flex;
                    flex-direction: column;
                }
                &.discount {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    opacity: 0.6;
                    text-decoration-line: line-through;
                }
            }
            &-discount {
                position: absolute;
                bottom: 8px;
                left: 0;
                padding: 2px 16px;
                background-color: var(--color-red);
                border-radius: 8px;
                color: var(--color-white);
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
            }
            &-image {
                &-wrapper {
                    background: var(--color-gray-50);
                    border-radius: 12px;
                    min-width: 64px;
                    width: 64px;
                    height: 64px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                min-width: 32px;
                width: 32px;
                height: 32px;
                object-fit: contain;
            }
            border-bottom: 0.5px solid #dadada;
            &:last-child {
                border-bottom: none;
            }
            &-name {
                margin-left: 16px;
            }
        }
    }
}
