.animate-fade {
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-delay: 0s;
  animation-fill-mode: backwards;
}

@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate {
  animation-duration: 0.5s;
  animation-name: animate-bounce;
  animation-delay: 0.25s;
  animation-fill-mode: backwards;
}

@keyframes animate-bounce {
  0% {
    top: -80px;
    right: -80px;
  }
  30% {
    top: -5px;
    right: -5px;
  }
  60% {
    top: -12px;
    right: -12px;
  }
  100% {
    top: 0px;
    right: 0px;
  }
}

.animate-jump {
  animation-name: jump;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0;
}

@keyframes jump {
  0%,
  5%,
  10% {
    transform: translate3d(0, 0, 0);
  }

  2.5%,
  7.5% {
    transform: translate3d(0, -5px, 0);
  }
}
