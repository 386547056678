@import "/src/assets/styles/mixins.scss";

.basket {
    display: flex;
    flex-direction: column;

    &-card {
        display: flex;
        flex-direction: column;
        background-color: var(--color-white);
        padding: 24px;
        border-radius: 16px;
    }
    .list {
        flex: 1;
        padding: 0;
        .product-list {
            flex: 1;
            display: flex;
            flex-direction: column;
        }
        .checkout {
            display: flex;
        }
    }
    .form {
        width: 448px;
        align-self: flex-start;
        .first-time {
            display: flex;
            align-items: center;
            margin-top: 24px;
            color: #9a9aa2;
            padding: 8px;
            border-radius: 16px;
            &.active {
                background-color: rgba(50, 217, 217, 0.15);
                color: var(--color-primary);
            }
            label {
                margin-left: 8px;
                font-size: 14px;
                text-align: center;
            }
        }

        .delivery {
            &-holder {
                display: flex;
                flex-direction: row;
                margin-top: 16px;
            }
            &-option {
                position: relative;
                cursor: pointer;
                flex: 1;
                margin: 0px 8px;
                background: #f2f2f2;
                border-radius: 8px;
                padding: 8px;
                text-align: center;
                color: var(--color-black);
                transition: 0.25s all;
                &-text {
                    opacity: 0.6;
                }
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
                &.selected {
                    background-color: var(--color-primary);
                }
                &.disabled {
                    opacity: 0.2;
                }
                &.free {
                    background-color: var(--color-primary);
                    color: var(--color-black);
                    .message {
                        position: absolute;
                        top: -10px;
                        left: 50%;
                        transform: translate(-50%, 0);
                        padding: 2px 6px;
                        background-color: var(--color-black);
                        color: var(--color-white);
                        border-radius: 6px;
                        font-size: 10px;
                    }
                }
            }
            &-message-btn {
                cursor: pointer;
                font-weight: 300;
                font-size: 14px;
                line-height: 17px;
                background-color: var(--color-primary);
                border-radius: 8px;
                padding: 8px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
            &-message-btn:hover {
                opacity: 0.7;
            }
        }
    }
    .checkout {
        padding: 24px;
        display: flex;
        align-items: center;
        &-clear {
            margin-right: 32px;
        }
        &-info {
            flex: 1;
        }
    }
    .label {
        color: #9a9aa1;
        font-weight: 400;

        font-size: 13px;
        line-height: 15.73px;
    }
    &-footer {
        min-width: 360px;
        &-content {
            display: flex;
            flex-direction: column;
            gap: 12px;
            justify-content: space-between;
        }
        &-price {
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
        }
        &-btn {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 24px;
            background-color: var(--color-white);
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.04);
            border-radius: 12px;
            border: 1px solid var(--color-gray-100);
            .yume-right {
                display: none;
            }
        }
    }
}

@include isMobile {
    .basket {
        padding-top: 0 !important;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 16px;
        &-footer {
            position: fixed;
            min-width: unset;
            width: 100%;
            z-index: 1000;
            bottom: 59px;
            padding: 0;
            left: 0;
            right: 0;

            &-price {
                font-size: 15px;
                font-weight: 600;
                line-height: 24px;
            }
            &-btn {
                padding: 12px 16px;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                border-radius: 0;
                .yume-right {
                    display: inherit;
                }
            }
        }
        &-product {
            width: calc(100% + 32px);
            margin-left: -16px;
            .image {
                width: 48px !important;
                height: 48px !important;
            }
        }
        .form {
            width: 100%;
            margin-top: 0;
            margin-left: 0;
        }
        &-card {
            padding: 24px 12px;
            border-radius: 0;
            .product-list {
                margin-bottom: 120px;
            }
        }
        .checkout {
            padding: 12px;
            flex-direction: column;
            &-clear {
                margin-right: 0px;
                margin-bottom: 12px;
            }
            &-info {
                width: 100%;
            }
        }
    }
}

.delivery-progress {
    padding: 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}

.details {
    width: 100%;
    margin-bottom: 12px 16px;
    display: flex;
    flex-direction: column;
    &-item {
        width: 50%;
        border: 1px solid var(--color-gray-100);
        padding: 16px;
        border-radius: 12px;
        display: flex;
        gap: 10px;
        align-items: center;
        cursor: pointer;

        &-status {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: var(--color-gray-300);
        }
        &.active {
            border-color: var(--color-primary);
            color: var(--color-primary);
            .details-item-status {
                border: 4px solid var(--color-primary);
                background: transparent;
            }
        }
        &.disabled {
            cursor: not-allowed;
            background: var(--color-gray-100);
            span {
                color: var(--color-gray-300);
            }
            .details-item-status {
                background: var(--color-gray-300);
            }
        }
    }
}

.attention {
    border-radius: 12px;
    background: var(--color-gray-100);
    padding: 16px;
    display: flex;
    gap: 12px;
    align-items: center;
    color: var(--color-gray-400);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    &-icon {
        border-radius: 50%;
        min-width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: var(--color-gray-200);
    }
}

.banner {
    background: var(--color-black) url("../../assets/images/black-card-bg.png") no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 12px;
    padding: 16px;
}
