$btn-color: var(--#{$prefix}body-color);
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow:
    inset 0 1px 0 rgba($white, 0.15),
    0 1px 1px rgba($black, 0.075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-link-color: var(--#{$prefix}link-color);
$btn-link-hover-color: var(--#{$prefix}link-hover-color);
$btn-link-disabled-color: var(--color-gray-400);

// Allows for customizing button radius independently from global border radius
$btn-border-radius: var(--#{$prefix}border-radius);
$btn-border-radius-sm: var(--#{$prefix}border-radius-sm);
$btn-border-radius-lg: var(--#{$prefix}border-radius-lg);

$btn-transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

$btn-hover-bg-shade-amount: 15%;
$btn-hover-bg-tint-amount: 15%;
$btn-hover-border-shade-amount: 20%;
$btn-hover-border-tint-amount: 10%;
$btn-active-bg-shade-amount: 20%;
$btn-active-bg-tint-amount: 20%;
$btn-active-border-shade-amount: 25%;
$btn-active-border-tint-amount: 10%;

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    align-content: center;
    padding: 16px;
    font-size: 13px;
    transition: 0.25s all;
    color: var(--color-black);
    border: 1px solid transparent;
    border-radius: 12px;

    &:focus,
    &:active,
    &:hover {
        outline: none;
        box-shadow: none;
        border: 1px solid transparent !important;
        color: var(--color-black) !important;
        background-color: transparent;
    }

    &-black {
        background-color: var(--color-black) !important;
        color: var(--color-white) !important;
        &-outline {
            border: 1px solid var(--color-black) !important;
            &:focus,
            &:active,
            &:hover {
                color: var(--color-black) !important;
                border: 1px solid var(--color-black) !important;
            }
        }
        &:focus,
        &:active,
        &:hover {
            color: var(--color-white) !important;
        }
    }

    &-default {
        background: var(--color-white);
        border: 1px solid #eaeaea;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
        &:focus,
        &:focus-visible,
        &:active,
        &:hover {
            border: 1px solid #eaeaea;
            border-color: #eaeaea;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        }
    }

    &-white {
        background-color: var(--color-white) !important;
        color: var(--color-black) !important;
        &-outline {
            background-color: var(--color-white) !important;
            color: var(--color-black) !important;
            border: 1px solid var(--color-black) !important;
            &:focus,
            &:active,
            &:hover {
                color: var(--color-black) !important;
                border: 1px solid var(--color-black) !important;
            }
        }
        &:focus,
        &:active,
        &:hover {
            color: var(--color-black) !important;
        }
    }

    &-gray {
        background-color: #f6f6f5;
        color: var(--color-black);
    }

    &-icon {
        font-size: 22px;
        outline: none;
        border: none;
        color: var(--color-white);
        background-color: transparent;
        &:focus,
        &:active,
        &:hover {
            outline: none;
            box-shadow: none;
            border: none;
        }
    }

    &:disabled {
        opacity: 0.3;
        border: 1px solid transparent;
    }

    &-red-8 {
        background-color: var(--color-red-8) !important;
        &:focus,
        &:active,
        &:hover {
            border: 1px solid transparent !important;
            background-color: var(--color-red-8) !important;
        }
    }

    @each $color-name, $color-value in $colors {
        &-#{"" + $color-name} {
            background-color: var(--color-#{$color-name}) !important;
            &:focus,
            &:active,
            &:hover {
                border: 1px solid transparent !important;
                background-color: var(--color-#{$color-name}-80) !important;
            }
        }
        &-color-#{"" + $color-name} {
            color: var(--color-#{$color-name}) !important;
            &:focus,
            &:active,
            &:hover {
                border: 1px solid transparent !important;
                color: var(--color-#{$color-name}) !important;
            }
        }
    }
}
