// $icomoon-font-family: "yume-icon" !default;
// $icomoon-font-path: "fonts" !default;

// $yume-down-arrow: "\e924";
// $yume-right-arrow: "\e925e";
// $yume-up-arrow: "\e926";
// $yume-left-arrow: "\e927";
// $yume-phone: "\e91c";
// $yume-like-off: "\e90b";
// $yume-like-on: "\e90e";
// $yume-checked: "\e916";
// $yume-add: "\e900";
// $yume-bag-2: "\e901";
// $yume-bag: "\e902";
// $yume-calendar: "\e903";
// $yume-category: "\e904";
// $yume-chevron-down: "\e905";
// $yume-chevron-left: "\e906";
// $yume-chevron-right: "\e907";
// $yume-chevron-up: "\e908";
// $yume-config: "\e909";
// $yume-date: "\e90a";
// $yume-document: "\e90c";
// $yume-edit: "\e90d";
// $yume-home: "\e90f";
// $yume-instagram: "\e910";
// $yume-inventory: "\e911";
// $yume-location: "\e912";
// $yume-menu: "\e913";
// $yume-minus: "\e914";
// $yume-paper: "\e915";
// $yume-profile: "\e917";
// $yume-question: "\e918";
// $yume-remove: "\e919";
// $yume-search: "\e91a";
// $yume-settings: "\e91b";
// $yume-start: "\e91d";
// $yume-stat: "\e91e";
// $yume-telegram: "\e91f";
// $yume-trash: "\e920";
// $yume-users: "\e921";
// $yume-whatsapp: "\e922";
// $yume-work: "\e923";
$icomoon-font-family: "yume-rental" !default;
$icomoon-font-path: "fonts" !default;

$yume-logo-path1: "\e932";
$yume-logo-path2: "\e933";
$yume-logo-path3: "\e934";
$yume-whatsapp: "\e92f";
$yume-instagram: "\e930";
$yume-telegram: "\e931";
$yume-show: "\e925";
$yume-hide: "\e90b";
$yume-basket: "\e903";
$yume-assets-pending: "\e900";
$yume-assets: "\e901";
$yume-bank-card: "\e902";
$yume-bonus: "\e904";
$yume-bottom: "\e905";
$yume-catalog: "\e906";
$yume-check: "\e907";
$yume-chevron-bottom: "\e908";
$yume-chevron-left: "\e909";
$yume-chevron-right: "\e90a";
$yume-chevron-top: "\e90c";
$yume-date: "\e90d";
$yume-document: "\e90e";
$yume-filter: "\e911";
$yume-globe: "\e90f";
$yume-hello: "\e910";
$yume-home: "\e912";
$yume-info: "\e913";
$yume-left: "\e914";
$yume-like: "\e915";
$yume-link: "\e916";
$yume-list: "\e917";
$yume-location: "\e918";
$yume-minus: "\e919";
$yume-outer-link: "\e91a";
$yume-phone: "\e91b";
$yume-plus: "\e91c";
$yume-prize: "\e91d";
$yume-question: "\e91e";
$yume-refresh: "\e91f";
$yume-remove: "\e920";
$yume-right: "\e921";
$yume-route: "\e922";
$yume-search: "\e923";
$yume-secret: "\e924";
$yume-star: "\e926";
$yume-time: "\e927";
$yume-timer: "\e928";
$yume-top: "\e929";
$yume-trash: "\e92a";
$yume-typography: "\e92b";
$yume-upload: "\e92c";
$yume-user: "\e92d";
$yume-y: "\e92e";

