.basket {
  justify-content: center;
  position: relative;
  &-product {
    display: flex;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid var(--color-gray-100);
    gap: 16px;
    &:last-child {
      border-bottom: none;
    }
    .image {
      width: 72px;
      height: 72px;
      min-width: 48px;
      object-fit: contain;
      border-radius: 8px;
      padding: 16px;
      background: var(--color-bg-light);
    }
    .detail {
      display: flex;
      align-items: center;
      flex: 1;
      gap: 8px;

      .info {
        flex: 1;
        &-name {
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 8px;
        }
        &-category {
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
        }
      }
      &-counter {
        display: flex;
        align-items: center;
        background: var(--color-bg-light);
        border-radius: 8px;
        margin: 0;
        &-amount {
          font-weight: 500;
          font-size: 13px;
          line-height: 17px;
          height: 32px;
          padding: 12px 16px;
          display: flex;
          justify-items: center;
          align-items: center;
          background: #f7f8fa;
        }
        &-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 32px;
          background-color: #efefef;
          border-radius: 8px;
          line-height: 1px;
          font-size: 10px;
          cursor: pointer;
          padding: 12px;
        }
      }
      .price {
        &-main {
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 8px;
        }
        &-detailed {
          font-weight: 600;
          font-family: "Inter", sans-serif;
          font-size: 13px;
          line-height: 17px;
        }
        .width {
          max-width: 120px;
          min-width: 80px;
        }
      }
    }
    &-delete {
      flex-direction: row;
    }
    .delete {
      padding: 8px;
      margin-left: 12px;
      &-message {
        flex: 1;
      }
      &-countdown {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .basket-product {
    padding: 12px;
    align-items: flex-start;
    .image {
      padding: 8px;
    }
    .detail {
      flex-direction: column;
      align-items: start;
      .info {
        &-name {
          font-size: 15px;
          line-height: 22px;
          margin-bottom: 4px;
        }
        &-category {
          font-size: 12px;
          line-height: 15px;
        }
      }
      .price {
        &-main {
          margin-bottom: 4px;
        }
      }
      &-counter {
        margin: 0 24px 0 0;
      }
    }
    &-delete {
      flex-direction: column;
    }
    .delete {
      margin-left: 4px;
    }
  }
}
