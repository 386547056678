.category {
  font-weight: 500;
  &-holder {
    width: calc(100% + 24px);
    margin-left: -12px;
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-item {
    width: max-content;
    margin-right: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    padding: 12px;
    background-color: var(--color-white);
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    width: max-content;
    .icon {
      width: 24px;
      height: 20px;
      object-fit: contain;
      filter: invert(1);
    }
    .category {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.category-product {
  cursor: pointer;
  padding: 16px;
  border-radius: 12px;
  background-color: var(--color-bg-light);
  border: 1px solid var(--color-gray-100);
  &__badge {
    padding: 4px 8px;
    border-radius: 8px;
    
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    text-transform: capitalize;
    
    &.kit {
      background: var(--color-black);
      color: var(--color-white);
    }
    &.discount {
      background: var(--color-red);
      color: var(--color-white);
    }
  }
  &-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
  .info {
    margin: 0 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    &-holder {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &-icon {
      min-width: 60px;
      max-width: 60px;
      height: 60px;
      object-fit: contain;
    }
    &-price {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;

      &.discount {
        color: var(--color-red);
        text-decoration: line-through;
      }
    }
    &-services {
      font-weight: 300;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: left;
      max-width: calc(100% - 100px);
      color: rgba(0, 0, 0, 0.8);
    }
  }
  .set {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    position: absolute;
    background-color: var(--color-primary);
    border-radius: 100px 32px 32px 8px / 60px 32px 32px 8px;
    padding: 10px 24px;
    bottom: -24px;
    right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .category {
    color: inherit;
    font-size: 13px;
    &-item {
      padding: 10px 12px;
      cursor: pointer;
      border: 1px solid transparent;
      border-radius: 16px;
      background: var(--color-gray-100);
      margin-right: 8px;
      &.selected {
        background: var(--color-black);
        color: var(--color-white);
        .icon {
          filter: inherit;
        }
      }
    }
  }

  .category-product {
    background-color: var(--color-bg-light);
    position: relative;
    border: 1px solid var(--color-gray-100);
    &-list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 12px;
      grid-row-gap: 12px;
    }
    .info {
      margin: 0 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      &-holder {
        position: static;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      &-price {
        text-align: left;
        
        font-size: 13px;
        font-weight: 500;
        &.discount {
          color: var(--color-red);
          text-decoration: line-through;
        }
      }
    }
    .set {
      bottom: 0;
    }
  }
}

@media screen and (min-width: 2560px) {
  .category-product {
    &-list {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}
