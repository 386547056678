.wrapper {
  position: relative;
  text-align: left;
  background-color: var(--color-light);
  width: 100%;
  display: flex;
  transition: 0.25s all;
  flex-direction: row;
  align-items: flex-start;
  display: grid;
  align-items: flex-start;
  grid-template-columns: 331px 1fr;
  .right-side {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    overflow-y: scroll;
    background-color: var(--color-white);
    align-self: stretch;
    flex-grow: 1;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    .content {
      margin-top: 0px;
      flex: 1;
      width: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;
      &-holder {
        overflow-x: hidden;
        display: flex;
        padding: 40px;
        min-height: calc(100vh - 96px);
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .side {
    display: inherit;
  }
  .wrapper {
    display: inherit;
    .right-side {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      .content-holder {
        padding: 24px 16px;
        min-height: calc(100vh - 114px);
        overflow-y: unset;
      }
      .content-header {
        display: none;
      }
    }
  }
  .content-holder {
    padding: 24px 16px;
  }
}

.white-space,
.nowrap {
  white-space: nowrap;
}
