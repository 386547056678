@import "/src/assets/styles/mixins.scss";

.portal-alert {
    position: fixed;
    min-width: 360px;
    z-index: 10;
    border-radius: 12px;
    background-color: var(--color-white);
    animation: raise 0.25s ease-in-out;
    cursor: pointer;
    transition: all;
    bottom: 8px;
    right: 8px;
    z-index: 1000;
    .notification {
        z-index: 2;
        gap: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 12px;
        background-color: #ffffff;
        width: 100%;
        &-icon {
            width: 48px;
            min-width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            background: var(--color-primary-8);
        }
    }
}

@include isMobile {
    .portal-alert {
        right: 8px;
        left: 8px;
        animation: raise 0.25s ease-in-out;
        .notification {
            left: unset;
            right: 24px;
            bottom: 24px;
            min-width: fit-content;
        }
    }
}

@keyframes raise {
    0% {
        opacity: 0;
        transform: translateY(16px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
