@import "assets/styles/mixins.scss";

.articles-slider {
  width: calc(100% + 80px);
  padding-left: 40px !important;
  margin-left: -40px !important;
  &-slide {
    min-width: 440px !important;
    width: 440px !important;
    cursor: pointer;
    &:last-child {
      margin-right: 40px;
    }
    .image {
      width: 440px;
      height: 220px;
      object-fit: cover;
      background: var(--color-white);
      border-radius: 12px;
    }
  }
}

.article {
  width: calc(100% + 64px);
  margin-top: -40px;
  margin-bottom: -32px;
  margin-left: -32px;
  max-height: 80vh;
  overflow-y: scroll;
  padding: 48px 32px;
  &-image {
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
  &-content {
    margin: 24px 0;
  }
  &-products-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 248px;
    grid-column-gap: 40px;
    grid-row-gap: 48px;
  }
}

@include isMobile {
  .articles-slider {
    width: calc(100% + 32px);
    padding-left: 16px !important;
    margin-left: -16px !important;
    &-slide {
      width: unset !important;
      min-width: unset !important;
      .swiper-slide-next {
        width: 114px !important;
      }
      .swiper-slide-active {
        width: 221px !important;
      }
      &:last-child {
        margin-right: 12px;
      }
      .image {
        width: 220px;
        height: 110px;
        object-fit: cover;
        background: var(--color-white);
        border-radius: 12px;
      }
    }
  }
  .article {
    width: calc(100% + 48px);
    margin-top: -24px;
    margin-bottom: -24px;
    margin-left: -24px;
    padding: 48px 24px;
  }
}
