.slider {
    width: calc(100% + 80px);
    padding-left: 40px !important;
    margin-left: -40px !important;
    margin-bottom: 32px;
    &-slide {
        max-width: 420px;
        &:last-child {
            margin-right: 0;
        }
    }
    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        bottom: 0;
    }
    .swiper-pagination-bullet {
        transition: 0.25s all;
        border-radius: 4px;
        &-active {
            width: 64px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .slider {
        width: calc(100% + 32px);
        padding-left: 0 !important;
        margin-left: -16px !important;
        margin-bottom: 16px;
        &-slide {
            width: calc(100% - 16px);
            max-width: calc(100% - 32px) !important;
            &:last-child {
                margin-right: 0px;
            }
        }
    }
}
