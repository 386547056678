@import "/src/assets/styles/mixins.scss";

footer {
  width: calc(100%  + 40px);
  margin-left: -20px;
  background-color: var(--color-white);
  padding: 40px 60px;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.08);
  border-top: 1px solid var(--color-gray-100);
  .contacts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid var(--color-gray-100);
  }
  .link {
    text-decoration: underline;
  }
  .documents {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    &-logo {
      height: 24px;
      object-fit: contain;
    }
  }
  @include isMobile {
    display: none;
  }
}
