.product-list-holder {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 24px;
}

@media screen and (min-width: 1200px) {
  .product-list-holder {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (max-width: 1300px) {
  .product-list-holder {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .product-list-holder {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 0) and (max-width: 768px) {
  .product-list-holder {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 0) and (max-width: 500px) {
  .product-list-holder {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
}

@media screen and (max-width: 768px) and (min-width: 500px) {
  .product-list-holder {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .product-list-holder {
    display: grid;
    //grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 2560px) {
  .product-list-holder {
    grid-template-columns: repeat(6, 1fr);
  }
}

.checked-icon {
  background: var(--color-primary);
  border-radius: 50%;
  min-width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
