@import "/src/assets/styles/mixins.scss";

.order-card {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--color-white);
    border-radius: 12px;
    border: transparent 1px solid;
    color: var(--color-black);
    transition: 0.25s all;
    gap: 24px;
    &-arrow {
        height: 40px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 11px;
        color: #c0c0c3;
        background: var(--color-bg-light);
    }
    &-status {
        padding: 4px 12px;
        width: fit-content;
        border-radius: 6px;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        &.turquoise {
            color: var(--color-primary);
            background: rgba(50, 217, 217, 0.1);
        }
        &.red {
            color: #eb5555;
            background: rgba(230, 63, 63, 0.1);
        }
        &.green {
            color: #42bf77;
            background: rgba(66, 191, 119, 0.1);
        }
    }
    &-photo {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        font-size: 12px;
        line-height: 15px;
        color: #9a9aa2;
        background: var(--color-bg-light);
    }
    &-price {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
    }
    &-info {
        &-id {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
        }
        &-items {
            font-size: 13px;
            line-height: 18px;
        }
        &-time {
            font-size: 12px;
            line-height: 140%;
            color: #9a9aa2;
            white-space: nowrap;
        }
    }
    &-hr {
        margin: 0;
        height: 54px;
        border: 1px solid #edeef0;
        opacity: 1;
    }
    &:hover {
        border: var(--color-primary) 1px solid;
        .order-card-arrow {
            background: rgba(50, 217, 217, 0.15);
            color: var(--color-primary);
        }
    }
}

.order-card-current {
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    gap: 16px;
    background-color: var(--color-white);
    border-radius: 12px;
    border: 1px solid var(--color-gray-100);
    .table {
        display: flex;
        gap: 8px;
        font-size: 13px;
        &-key {
            min-width: 140px;
            max-width: 140px;
            color: var(--color-gray-400);
        }
        &-value {
            flex: fill;
            font-weight: 500;
            color: var(--color-black);
        }
    }
}

@include isMobile {
    .order-card {
        flex-direction: column;
        padding: 12px;
        gap: 12px;
        &-link {
            gap: 12px;
            width: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            color: var(--color-black);
        }
        &-info-time {
            display: flex;
            align-items: center;
            span {
                text-align: right;
            }
        }
    }
}
