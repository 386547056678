@import "/src/assets/styles/mixins.scss";

.grant {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-header {
        max-width: 768px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 0;
        &-slogan {
            font-size: 48px;
            text-align: center;
            font-weight: 700;
            margin-bottom: 16px;
        }
        &-period {
            font-size: 18px;
            margin-bottom: 32px;
        }
    }
    &-goals {
        max-width: 768px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 0;
        &-header {
            font-weight: 700;
            font-size: 28px;
            margin-bottom: 24px;
        }
        &-text {
            font-weight: 400;
            font-size: 16px;
            text-align: center;
        }
    }
    &-steps {
        max-width: 768px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 0;
        &-header {
            font-size: 28px;
            margin-bottom: 32px;
            font-weight: 700;
        }
        &-steps {
            display: flex;
            .item {
                display: flex;
                flex-direction: column;
                background-color: var(--color-bg-light);
                flex: 1;
                padding: 16px;
                margin-right: 16px;
                border-radius: 8px;
                :last-child {
                    margin-right: 0;
                }
                &-header {
                    color: var(--color-gray-300);
                    margin-bottom: 8px;
                    font-size: 14px;
                }
                &-text {
                    font-weight: 500;
                    font-size: 16px;
                }
            }
        }
    }
    &-requirements {
        max-width: 768px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 0;
        &-header {
            font-size: 28px;
            margin-bottom: 32px;
            font-weight: 700;
        }
        &-grid {
            display: grid;
            grid-gap: 24px;
            grid-template-columns: repeat(2, 1fr);
            margin-bottom: 32px;
            font-size: 16px;
            font-weight: 400;
            color: var(--color-gray-500);

            .item {
                padding: 16px;
                border-radius: 8px;
                font-size: 14px;
                font-weight: 600;
                background-color: var(--color-primary-20);
                display: flex;
                flex-direction: row;
                align-items: center;

                &-check {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 16px;
                    width: 16px;
                    height: 16px;
                    margin-right: 12px;
                    border-radius: 50%;
                    color: var(--color-white);
                    background-color: var(--color-primary);
                }
            }
        }
    }
    @include isMobile {
        &-header {
            align-items: start;
            padding: 40px 0;
            border-bottom: 1px solid var(--color-gray-100);
            &-slogan {
                font-size: 28px;
                text-align: left;
            }
            &-period {
                margin-bottom: 24px;
            }
        }
        &-goals {
            padding: 40px 0;
            border-bottom: 1px solid var(--color-gray-100);
            &-header {
                font-weight: 600;
                font-size: 18px;
                margin-bottom: 16px;
            }
            &-text {
                font-size: 14px;
            }
        }
        &-steps {
            padding: 40px 0;
            border-bottom: 1px solid var(--color-gray-100);
            width: 100%;
            &-header {
                font-size: 18px;
                margin-bottom: 24px;
                font-weight: 600;
            }
            &-steps {
                display: flex;
                flex-direction: column;
                width: 100%;
                .item {
                    flex-direction: row;
                    align-items: center;
                    margin-right: 0px;
                    margin-bottom: 16px;
                    &:last-child {
                        margin-bottom: 0px;
                    }
                    &-header {
                        margin-bottom: 0px;
                        margin-right: 8px;
                    }
                }
            }
        }
        &-requirements {
            padding: 40px 0;
            border-bottom: 1px solid var(--color-gray-100);
            width: 100%;
            &-header {
                font-size: 18px;
                margin-bottom: 24px;
                font-weight: 600;
            }
            &-grid {
                width: 100%;
                font-size: 14px;
                display: grid;
                grid-gap: 16px;
                grid-template-columns: repeat(1, 1fr);
                margin-bottom: 16px;
            }
            .btn {
                width: 100%;
            }
        }
    }
}
