.payment-wrapper {
  margin: 0 -16px;
  display: flex;
  flex-direction: column;
}
.payment-header-container {
  margin-bottom: 8px;
  padding: 20px 16px;
  display: flex;
  align-items: center;
  background: #ffffff;
}
.payment-header-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #151324;
}
.payment-info-container {
  margin-bottom: 8px;
  background: #ffffff;
}
.payment-info-row {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment-info-hr {
  margin: 0;
  width: 100%;
  border: 1px solid #efefef;
}
.payment-info-text {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}
.payment-info-subtext {
  font-size: 13px;
  line-height: 16px;
  color: #9a9aa2;
}
.payment-bonus-switch-container {
  display: flex;
  align-items: center;
}
.switch {
  display: flex;
}
.switch input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}
.switch label {
  cursor: pointer;
  text-indent: -99999px;
  width: 40px;
  height: 24px;
  background-color: #e7e7e7;
  display: block;
  border-radius: 100px;
  position: relative;
}
.switch input:checked + label {
  background-color: #32d9d9;
}
.switch input:checked + label:after {
  left: calc(100% - 4px);
  transform: translateX(-100%);
}
.switch label:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border-radius: 90px;
  transition: 0.3s;
}
.payment-info-text.spend-bonus {
  color: #32d9d9;
}
.payment-info-text.bold {
  font-weight: 700;
}
.payment-buttons-container {
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}
.payment-btn {
  padding: 16px 32px;
  height: 50px;
  background: #edeef0 !important;
}
.payment-btn > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.payment-btn.card-pay {
  background: #32d9d9 !important;
}
.payment-btn.kaspi-pay {
  color: #ffffff !important;
  background: #eb3434 !important;
}
.payment-btn.cancel {
  color: #ffffff !important;
  background: #eb5555 !important;
}

.payment-confirm-header {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #151324;
}
.payment-confirm-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #6f6f74;
}
.copy-price-input {
  padding: 16px;
  height: 49px;
  flex-grow: 1;
  border-radius: 8px;
  border: none;
  background: var(--color-gray-100);
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
}
.copy-price-btn {
  padding: 16px;
  height: 49px;
  border-radius: 8px;
  background: #32d9d9;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  &:hover {
    border-color: #32d9d9 !important;
  }
}
.payment-buttons-container.no-padding {
  padding: 0;
}
.receivement-result .modal-body {
  padding: 48px 24px 32px 24px;
}
.bill-btn-wrapper {
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  padding: 16px;
}

@media screen and (min-width: 1025px) {
  .payment-wrapper {
    margin: 0;
    gap: 20px;
  }
  .payment-header-container {
    margin: 0;
    padding: 0;
    justify-content: center;
  }
  .payment-header-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
  .payment-info-wrapper {
    gap: 8px;
    display: flex;
    flex-direction: column;
  }
  .payment-info-container {
    margin: 0;
    border-radius: 8px;
    background: #f7f8fa;
  }
  .payment-info-row {
    padding: 16px;
  }
  .payment-info-hr {
    border: 1px solid #edeef0;
    opacity: 1;
  }
  .payment-info-text.left {
    font-weight: 400;
    color: #6f6f74;
  }
  .payment-buttons-container {
    padding: 0;
  }
  .second-backdrop {
    z-index: 1055;
  }
  .bill-btn-wrapper {
    position: static;
    margin-top: 14px;
    padding: 0;
  }
}
