@import "/src/assets/styles/mixins.scss";
@import "/src/assets/styles/shadow.scss";

.order-wrapper {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0;
  @include isMobile {
    flex-direction: column;
    margin-bottom: 32px;
    gap: 0;
  }
  &-status {
    margin-bottom: 16px;
    padding: 20px 24px;
    gap: 32px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 12px;
    box-shadow: 0px 4px 8px var(--color-black-8);
    @include isMobile {
      border-radius: 0px;
      flex-direction: column;
      background-color: unset;
      box-shadow: none;
      padding: 0;
      gap: 8px;
      .ant-timeline-item.ant-timeline-item-last {
        padding-bottom: 0;
        .ant-timeline-item-content {
          min-height: 0;
        }
      }
    }
  }
  &-info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &-title {
      margin-bottom: 4px;
      width: auto;
      font-size: 15px;
      font-weight: 400;
      color: var(--color-gray-400);
    }
    &-value {
      font-weight: 400;
      font-size: 14px;
    }
    @include isMobile {
      flex-direction: row;
      &-title {
        margin-bottom: 0;
        padding-right: 4px;
        width: 120px;
        font-size: 14px;
      }
      &-value {
        font-weight: 500;
      }
    }
  }
  &-payment {
    padding: 20px 24px;
    width: 360px;
    border-radius: 12px;
    background-color: var(--color-white);
    box-shadow: 0px 4px 8px var(--color-black-8);
    @include isMobile {
      padding: 0;
      border-radius: 0;
      box-shadow: none;
    }
    &-title {
      margin-bottom: 16px;
      font-weight: 700;
      font-size: 18px;
      @include isMobile {
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  &-content {
    @include isMobile {
      width: 100%;
    }
  }

  .status-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: none;
    background: var(--color-gray-300);
    &.pending {
      width: 8px;
      height: 8px;
      background: var(--color-primary);
      border: none;
      outline: 5px solid var(--color-primary-8);
    }
    &.done {
      width: 8px;
      height: 8px;
      background: var(--color-primary);
      border: none;
    }
  }
}

.order-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  box-shadow: 0px 4px 8px var(--color-black-8);
  padding: 16px 24px 16px 16px;
  border-radius: 12px;
  background-color: var(--color-white);
  margin-bottom: 16px;
  & > .order-container {
    padding: 12px 0;
    padding-left: 12px;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
    .order-container-image {
      width: 50px;
      height: 50px;
    }
  }
  &-image {
    padding: 5px;
    width: 60px;
    height: 60px;
    object-fit: contain;
    background: var(--color-bg-light);
    border-radius: 8px;
    margin-right: 8px;
    &.no-image {
      padding: 16px;
    }
  }
  &-name {
    font-size: 16px;
    font-weight: 600;
    @include isMobile {
      font-size: 14px;
    }
  }
  &:last-child {
    border-bottom: none;
  }
  @include isMobile {
    box-shadow: none;
    padding: 12px 0;
    border-radius: 0;
    margin-bottom: 0;
    border-bottom: 1px solid var(--color-gray-100);
  }
}

.order-payment-row {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  &-left {
    font-size: 15px;
    color: var(--color-gray-400);
    @include isMobile {
      font-size: 13px;
    }
  }
  &-right {
    font-weight: 500;
    font-size: 15px;
    @include isMobile {
      font-size: 13px;
    }
    &.bonus-count {
      color: var(--color-primary);
    }
    &.payment-status {
      color: var(--color-gray-400);
      &.success {
        color: var(--color-green);
      }
      &.error {
        color: var(--color-red);
      }
    }
  }
}

.order-payment-buttons {
  margin-top: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.proceed-payment-btn {
  padding: 16px 32px;
  height: 50px;
  border: none;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #151324;
  background: #32d9d9;
  cursor: pointer;
}
.proceed-payment-btn.disabled {
  color: #9a9aa2;
  background: #dadce0;
  pointer-events: none;
}
a.proceed-payment-btn {
  color: initial;
}
.cancel-order-btn {
  padding: 16px 32px;
  border: none;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #ffffff;
  background: #151324;
  cursor: pointer;
  &.disabled {
    color: #9a9aa2;
    background: #dadce0;
    pointer-events: none;
  }
}
.order-again-wrapper {
  padding: 12px 16px;
  position: fixed;
  bottom: 60px;
  left: 0;
  right: 0;
  background: #ffffff;
}
