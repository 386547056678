.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  &-wrapper {
    display: none;
  }
  &-bonus {
    gap: 4px;
    padding: 4px 10px 4px 10px !important;;
    border-radius: 16px !important;
  }
}

@media screen and (max-width: 1024px) {
  .header {
    display: flex;
    height: 54px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-gray-100);
    &-wrapper {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 100;
      background-color: var(--color-white);
    }
    .icon {
      height: 36px;
      object-fit: contain;
      width: 108px;
    }
    &-favorite {
      position: relative;
      font-size: 21px;
      &-badge {
        position: absolute;
        top: -4px;
        right: -4px;
        border-radius: 50%;
        background: var(--color-primary);
        width: 9px;
        height: 9px;
        border: 1.5px solid var(--color-white);
      }
    }
  }
}
