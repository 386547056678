@import "/src/assets/styles/mixins.scss";

aside {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 331px !important;
  overflow-y: scroll;
  color: var(--color-white);
  height: 100vh;
  background-color: var(--color-black);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  .spinner-holder {
    width: 100%;
    height: 100%;
  }
  .logo-header {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 96px;
    max-height: 96px;
    padding: 24px 64px 24px 48px;
  }
  .menu {
    width: 100%;
    padding: 40px 64px 40px 48px;
    &-item {
      cursor: pointer;
      width: 100%;
      display: flex;
      padding: 12px 0;
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;

      color: var(--color-white);
      text-decoration: none;
      .icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
      &.active {
        color: var(--color-primary);
        outline: none;
        position: relative;
        //.icon {
        //  filter: brightness(0%);
        //}
      }
    }
    .switcher {
      background-color: transparent !important;
      padding: 0px !important;
    }
    .switcher-btn {
      border: 0px solid transparent !important;
      background-color: transparent !important;
      border-radius: 0px !important;
      border-bottom: 2px solid transparent !important;
      color: var(--color-white) !important;
      &.selected {
        background-color: transparent !important;
        border-bottom: 2px solid var(--color-primary) !important;
      }
    }
  }
  .request-phone {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    border-radius: 12px;
    font-size: 18px;
    padding: 12px 24px;
    color: var(--color-black);
    &:active,
    &:focus,
    &:hover,
    &:focus-visible,
    &:focus-within {
      color: var(--color-black);
      opacity: 1;
    }
  }
}

.logo {
  width: 143px;
  height: 28px;
}

@include isMobile {
  aside {
    display: none;
  }
  .logo {
    width: 123px;
    height: 24px;
  }
}
