@import "/src/assets/styles/mixins.scss";

.grant {
    &-winners {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 80px;
        @include isMobile {
            padding-top: 24px;
        }
        &-header {
            font-size: 28px;
            margin-bottom: 32px;
            font-weight: 700;
            margin-bottom: 32px;
            @include isMobile {
                font-size: 18px;
                margin-bottom: 24px;
                font-weight: 600;
                margin-bottom: 12px;
            }
        }
    }

    &-winner-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        gap: 16px;
        background-color: var(--color-bg-light);
        border-radius: 12px;
        .avatar {
            width: 120px;
            min-width: 120px;
            height: 120px;
            object-fit: cover;
            background-color: var(--color-bg-light);
            border-radius: 10px;
            @include isMobile {
                width: 104px;
                min-width: 104px;
                height: 104px;
            }
        }
        .btn {
            width: max-content !important;
            padding: 8px !important;
            border-radius: 8px !important;
        }
        @include isMobile {
            flex-direction: row;
            align-items: center;
        }
    }

    &-wrapper {
        .winner {
            display: flex;
            align-items: center;
            flex-direction: row;
            padding: 0 0 40px 0;
            &-avatar {
                width: 200px;
                height: 200px;
                object-fit: cover;
                margin-right: 24px;
                border-radius: 8px;
                @include isMobile {
                    width: 120px;
                    height: 120px;
                    margin-right: 16px;
                }
            }
            @include isMobile {
                padding: 0;
                padding-bottom: 24px;
            }
        }
        .detail {
            &-header {
                font-size: 24px;
                font-weight: 600;
            }
            &-content {
                margin-top: 16px;
                color: var(--color-gray-400);
            }
        }
        &-basket {
            &-header {
                font-size: 24px;
                font-weight: 600;
            }
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 80px 0;
            @include isMobile {
                padding: 0;
            }
            &-product {
                gap: 16px;
                background-color: var(--color-bg-light);
                display: flex;
                align-items: center;
                padding: 16px 24px 16px 16px;
                border-radius: 12px;
                margin-bottom: 8px;

                &:first-child {
                    margin-top: 24px;
                    @include isMobile {
                        margin-top: 8px;
                    }
                }
                &:last-child {
                    margin-bottom: 24px;
                    @include isMobile {
                        margin-bottom: 8px;
                        border-bottom: none;
                    }
                }
                &-image {
                    padding: 10px;
                    border-radius: 8px;
                    background-color: var(--color-gray-100);
                    img {
                        width: 60px;
                        height: 60px;
                        object-fit: contain;
                    }
                    @include isMobile {
                        padding: 5px;
                        img {
                            width: 50px;
                            height: 50px;
                        }
                    }
                }
                @include isMobile {
                    gap: 12px;
                    background-color: transparent;
                    margin-bottom: 0;
                    border-bottom: 1px solid var(--color-divider);
                    padding: 16px 0;
                }
            }
        }
    }
}
