.community {
  width: 100%;
  &-image {
    width: 100%;
    height: 440px;
    object-fit: cover;
    border-radius: 12px;
  }
  &-content-holder {
    margin: 24px 0;
    display: flex;
    flex-direction: row;
  }
  &-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 32px;
    &-qr {
      width: 196px;
      height: 196px;
    }
    &-btn {
      padding: 16px 24px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      width: 100%;
      margin: 0px;
      margin-top: 16px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .community {
    &-image {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
    &-content-holder {
      display: flex;
      flex-direction: column;
    }
    &-links {
      margin-left: 0px;
      &-qr {
        display: none;
      }
      &-btn {
        width: max-content;
        margin: 0px;
        margin-top: 16px;
      }
    }
  }
}
