@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ttpelk');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ttpelk#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ttpelk') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ttpelk') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ttpelk##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.yume {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.yume-logo .path1 {
  &:before {
    content: $yume-logo-path1;  
    color: rgb(48, 192, 226);
  }
}
.yume-logo .path2 {
  &:before {
    content: $yume-logo-path2;  
    margin-left: -5.107421875em;  
    color: rgb(255, 255, 255);
  }
}
.yume-logo .path3 {
  &:before {
    content: $yume-logo-path3;  
    margin-left: -5.107421875em;  
    color: rgb(48, 192, 226);
  }
}
.yume-whatsapp {
  &:before {
    content: $yume-whatsapp; 
  }
}
.yume-instagram {
  &:before {
    content: $yume-instagram; 
  }
}
.yume-telegram {
  &:before {
    content: $yume-telegram; 
  }
}
.yume-show {
  &:before {
    content: $yume-show; 
  }
}
.yume-hide {
  &:before {
    content: $yume-hide; 
  }
}
.yume-basket {
  &:before {
    content: $yume-basket; 
  }
}
.yume-assets-pending {
  &:before {
    content: $yume-assets-pending; 
  }
}
.yume-assets {
  &:before {
    content: $yume-assets; 
  }
}
.yume-bank-card {
  &:before {
    content: $yume-bank-card; 
  }
}
.yume-bonus {
  &:before {
    content: $yume-bonus; 
  }
}
.yume-bottom {
  &:before {
    content: $yume-bottom; 
  }
}
.yume-catalog {
  &:before {
    content: $yume-catalog; 
  }
}
.yume-check {
  &:before {
    content: $yume-check; 
  }
}
.yume-chevron-bottom {
  &:before {
    content: $yume-chevron-bottom; 
  }
}
.yume-chevron-left {
  &:before {
    content: $yume-chevron-left; 
  }
}
.yume-chevron-right {
  &:before {
    content: $yume-chevron-right; 
  }
}
.yume-chevron-top {
  &:before {
    content: $yume-chevron-top; 
  }
}
.yume-date {
  &:before {
    content: $yume-date; 
  }
}
.yume-document {
  &:before {
    content: $yume-document; 
  }
}
.yume-filter {
  &:before {
    content: $yume-filter; 
  }
}
.yume-globe {
  &:before {
    content: $yume-globe; 
  }
}
.yume-hello {
  &:before {
    content: $yume-hello; 
  }
}
.yume-home {
  &:before {
    content: $yume-home; 
  }
}
.yume-info {
  &:before {
    content: $yume-info; 
  }
}
.yume-left {
  &:before {
    content: $yume-left; 
  }
}
.yume-like {
  &:before {
    content: $yume-like; 
  }
}
.yume-link {
  &:before {
    content: $yume-link; 
  }
}
.yume-list {
  &:before {
    content: $yume-list; 
  }
}
.yume-location {
  &:before {
    content: $yume-location; 
  }
}
.yume-minus {
  &:before {
    content: $yume-minus; 
  }
}
.yume-outer-link {
  &:before {
    content: $yume-outer-link; 
  }
}
.yume-phone {
  &:before {
    content: $yume-phone; 
  }
}
.yume-plus {
  &:before {
    content: $yume-plus; 
  }
}
.yume-prize {
  &:before {
    content: $yume-prize; 
  }
}
.yume-question {
  &:before {
    content: $yume-question; 
  }
}
.yume-refresh {
  &:before {
    content: $yume-refresh; 
  }
}
.yume-remove {
  &:before {
    content: $yume-remove; 
  }
}
.yume-right {
  &:before {
    content: $yume-right; 
  }
}
.yume-route {
  &:before {
    content: $yume-route; 
  }
}
.yume-search {
  &:before {
    content: $yume-search; 
  }
}
.yume-secret {
  &:before {
    content: $yume-secret; 
  }
}
.yume-star {
  &:before {
    content: $yume-star; 
  }
}
.yume-time {
  &:before {
    content: $yume-time; 
  }
}
.yume-timer {
  &:before {
    content: $yume-timer; 
  }
}
.yume-top {
  &:before {
    content: $yume-top; 
  }
}
.yume-trash {
  &:before {
    content: $yume-trash; 
  }
}
.yume-typography {
  &:before {
    content: $yume-typography; 
  }
}
.yume-upload {
  &:before {
    content: $yume-upload; 
  }
}
.yume-user {
  &:before {
    content: $yume-user; 
  }
}
.yume-y {
  &:before {
    content: $yume-y; 
  }
}

