.y-coin {
    border-radius: 16px;
    padding: 4px 7px 4px 10px;
    height: 28px;
    font-size: 13px;
    line-height: 13px;
    font-weight: 600;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 4px;
    box-shadow: 0 0 16px 0 rgba(50, 217, 217, 0.08);
    color: var(--color-white);
}