@import "/src/assets/styles/mixins.scss";

.breadcrumb {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &-header {
        display: flex;
        align-items: start;
        // line-height: 36px;
        gap: 16px;
        
        &-text {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 0px;
        }
        .yume {
            cursor: pointer;
            font-size: 24px;
        }
        @include isMobile {
            gap: 4px;
            .yume {
                cursor: pointer;
                font-size: 20px;
            }
            &-text {
                font-size: 20px;
            }
        }
    }

    @include isMobile {
        gap: 12px;
        &> .ant-breadcrumb {
            display: none;
        }
    }
}
