.switcher {
    padding: 4px;
    border-radius: 12px;
    display: flex;
    width: max-content;

    &-btn {
        width: max-content;
        font-size: 14px;
        font-weight: 500 !important;
        padding: 12px 40px;
        &.selected {
            background-color: var(--color-primary) !important;
        }
    }
}
