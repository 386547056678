@import "/src/assets/styles/mixins.scss";

.question {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 24px 0;
  &-body {
    width: 100%;
    display: flex;
    padding: 6px 0px;
    flex-direction: row;
    justify-content: flex-start !important;
    &-text {
      text-align: start;
      font-size: 18px !important;
      font-weight: 500;
    }
  }
  &-answer {
    font-size: 14px !important;
  }
}

.faq-header {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 32px;
}

@include isMobile {
  .faq-header {
    font-size: 21px;
    margin-bottom: 24px;
  }
  .question {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 12px 0;
    &-body {
      width: 100%;
      display: flex;
      padding: 6px 0px;
      flex-direction: row;
      justify-content: flex-start !important;
      &-text {
        text-align: start;
        font-size: 15px !important;
        font-weight: 500;
      }
    }
    &-answer {
      font-size: 15px !important;
    }
  }
}
