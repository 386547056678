.navigation {
  display: none;
}

.basket-count {
  position: absolute;
  right: 6px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  color: var(--color-white);
  background-color: var(--color-red);
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
}

@media screen and (max-width: 1024px) {
  .navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 21px;
    height: 60px;
    flex-direction: row;
    justify-content: space-between;
    background: var(--color-white);
    z-index: 100;
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.04);
    
    &-item {
      min-width: 52px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--color-white);
      &:active,
      &:hover,
      &:focus {
        color: var(--color-white);
      }
      .yume {
        font-size: 24px;
        color: var(--color-gray-300);
      }
      .name {
        margin-top: 6px;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: var(--color-gray-300);
      }
      &.active {
        color: var(--color-primary);
        .yume,
        .name {
          color: var(--color-primary);
        }
      }
    }
  }
}
