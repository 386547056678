@import "/src/assets/styles/mixins.scss";

.event-detail {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 24px;
  gap: 24px;

  &-content {
    display: none;
    margin-top: 12px;
    @include isMobile {
      display: block;
      margin-top: 0;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    flex: 1;

    & > .event-detail-content {
      display: block;
      @include isMobile {
        display: none;
      }
    }
  }

  &-description {
    p {
      padding: 0;
      margin: 0;
      white-space: pre-line;
      font-size: 14px;
      font-weight: 400;
      color: var(--color-black);
    }
  }

  @include isMobile {
    flex-direction: column;
  }
}
