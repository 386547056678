@import "/src/assets/styles/mixins.scss";

.layout-event-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-outlet {
        padding: 32px;
        margin-bottom: 0px;
        border-radius: 16px;
        width: 100%;
        max-width: 440px;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);
        margin: 24px;

        &-wrapper {
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            @include isMobile {
                height: unset;
                align-items: start;
            }
        }

        @include isMobile {
            margin: 0;
            width: 100%;
            max-width: unset;
            padding: 24px 16px;
            border: none;
            box-shadow: none;
        }
    }
    @include isMobile {
        align-items: flex-start;
    }
}
