.header-profile {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-end;
  gap: 32px;
  &-link {
    color: var(--color-black);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    width: max-content;
    
    font-size: 13px;
    font-weight: 500;
    height: 48px;
    .icon {
      position: relative;
      font-size: 21px;
      &-badge {
        position: absolute;
        color: var(--color-white);
        display: flex;
        align-items: center;
        justify-content: center;
        top: -2px;
        right: -2px;
        border-radius: 50%;
        background: var(--color-red);
        font-family:
          -system-ui,
          -apple-system,
          BlinkMacSystemFont,
          "Segoe UI",
          Roboto,
          Oxygen,
          Ubuntu,
          Cantarell,
          "Open Sans",
          "Helvetica Neue",
          sans-serif;
        &.small {
          top: 5px;
          width: 9px;
          height: 9px;
          border: 1.5px solid var(--color-white);
        }
        &.big {
          top: 0;
          right: -4px;
          width: 16px;
          height: 16px;
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
  &-avatar {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }
}
