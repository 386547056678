@import "/src/assets/styles/mixins.scss";

.counter {
    min-width: 140px;
    border-radius: 8px;
    background: var(--color-gray-100);
    display: flex;
    align-items: center;

    &-count {
        text-align: center;
        font-weight: 600;
        @include isMobile {
            font-size: 12px;
        }
    }
    &-btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px !important;

        @include isMobile {
            padding: 12px 16px !important;
        }
    }
    &-add {
        gap: 8px;
        padding: 16px !important;
        border-radius: 8px !important;
        @include isMobile {
            padding: 12px 20px !important;
        }
    }
}
