@import "/src/assets/styles/mixins.scss";

.content-header {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  height: 96px;
  border-bottom: 1px solid var(--color-gray-100);
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &-default {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    color: var(--color-black);
    gap: 8px;
    .yume-left {
      font-size: 24px;
      @include isMobile {
        font-size: 21px;
      }
    }
    @include isMobile {
      font-size: 21px;
    }
  }

  &-right {
    flex: 1;
    justify-self: flex-end;
    gap: 8px;
    display: flex;
    align-items: center;
  }

  @include isMobile {
    height: auto;
    padding: 8px 16px;
  }
}
