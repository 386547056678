.form-title {
  color: var(--color-gray-400);
  font-size: 13px;
  line-height: 16px;
}

.profile-edit-avatar {
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    object-fit: cover;
    background-color: var(--color-gray-100);
    color: var(--color-black);
    opacity: 1;
    transition: 0.25s all;
    &:hover {
      opacity: 0.7;
    }
  }
}
