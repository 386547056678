@import "/src/assets/styles/mixins.scss";

.list-slider {
  // padding-top: 20px;
  position: relative;
  &-header {
    font-size: 24px;
    margin-bottom: 16px;
  }
  &-slider {
    width: calc(100% + 80px);
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin-left: -40px !important;
    .swiper-pagination {
      bottom: 0 !important;
    }
    .swiper-slide {
      margin-right: 0px;
      margin-bottom: 24px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    padding: 8px 4px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-white) !important;
    z-index: 99;
    box-shadow: 0px 0px 7px 4px rgba(34, 60, 80, 0.08) !important;
    &::after {
      font-size: 16px;
      font-weight: 900;
      color: var(--color-black);
    }
  }
  .swiper-slide {
    margin-right: 24px !important;
    &:last-child {
      margin-right: 0;
    }
  }
}

@include isMobile {
  .list-slider {
    &-header {
      font-size: 20px;
    }
    &-slider {
      max-height: inherit;
      width: calc(100% + 32px);
      padding-left: 16px !important;
      padding-right: 16px !important;
      margin-left: -16px !important;
      .swiper-pagination {
        bottom: 0 !important;
      }
      .swiper-slide {
        margin-right: 20px !important;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &-border {
      width: calc(100% + 24px);
      margin-left: -12px !important;
      height: 0.5px;
      background-color: var(--color-primary);
    }
  }
}
