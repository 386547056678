@import "/src/assets/styles/mixins.scss";

.page {
  &-holder {
    display: flex;
    flex-direction: row;
    margin: 24px 0;
    gap: 16px;
  }
  &-item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 64px 16px 16px;
    background-color: var(--color-bg-light);
    border-radius: 12px;
    gap: 12px;

    &:last-child {
      margin-right: 0;
    }
    &-icon {
      height: 20px;
      object-fit: contain;
    }
    &-name {
      font-weight: 500;
      font-size: 14px;
    }
  }
}

@include isMobile {
  .page {
    &-holder {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;
    }
    &-item {
      flex-direction: column;
      padding: 16px;
      align-items: center;
      background: var(--color-bg-light);
      &-name {
        font-weight: 500;
        font-size: 13px;
      }
    }
  }
}

.checked-icon {
  background: var(--color-primary);
  border-radius: 50%;
  min-width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
