.mobile-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: -16px;
  margin-top: -24px;
  margin-bottom: -24px;
  margin-right: -16px;
  width: calc(100% + 32px);

  &-item {
    cursor: pointer;
    display: flex;
    gap: 12px;
    flex-direction: row;
    align-items: center;
    padding: 20px 16px;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: var(--color-black);
    text-decoration: none;
    height: 100%;
    border-bottom: 1px solid var(--color-gray-100);
    width: calc(100% + 32px);
    &:last-child {
      border-bottom: none;
    }
  }
  &-icon {
    -webkit-filter: brightness(0);
    filter: brightness(0);
    height: 24px;
    width: 24px;
    object-fit: contain;
  }
}
@media screen and (max-width: 1024px) {
}
