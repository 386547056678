@import "/src/assets/styles/mixins.scss";

.product-images {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    min-width: 320px;
    max-width: calc(100vw - 955px);
    width: calc(100vw - 955px);

    .virtual-swiper {
        max-width: calc(100vw - 955px);
        width: calc(100vw - 955px);
        min-width: 320px;
        margin: 16px 0 0 0;

        .image-holder {
            height: 100%;
            background-color: var(--color-white);
            object-fit: cover;
            width: 120px;
            height: 120px;
        }
        .swiper-slide {
            overflow: hidden;
            opacity: 0.7;
            min-width: 120px;
            max-width: 120px;
            width: 120px;
            height: 120px;
        }
        .thumb-active {
            opacity: 1;
        }
        @include isMobile {
            display: none;
        }
    }

    .main-swiper {
        width: calc(100vw - 955px);
        height: calc((100vw - 955px) * 3 / 4);
        min-width: 320px;
        min-height: 400px;
        max-height: 640px;
        border-radius: 8px;

        .swiper-wrapper {
            width: 100%;
            height: 100%;
        }
        .image-holder {
            width: 100%;
            height: 400px;
            background-color: var(--color-white);
            overflow: hidden;
            img {
                border-radius: 8px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        @include isMobile {
            width: 100%;
            min-height: unset;
            height: unset;

            .swiper-slide {
                width: inherit !important;
            }
            .swiper-pagination {
                bottom: -16px !important;
            }
        }
    }

    @include isMobile {
        width: 100%;
        min-width: unset;
        max-width: 100%;
        max-height: 360px;
    }
}
