@import "bootstrap/scss/bootstrap";
@import "assets/styles/colors.scss";
@import "assets/styles/variables.scss";
@import "assets/styles/animation.scss";
@import "assets/styles/functions.scss";
@import "assets/styles/mixins.scss";
@import "assets/styles/button.scss";
@import "assets/styles/shadow.scss";
@import "assets/styles/slider.scss";
@import "assets/styles/modal.scss";
@import "assets/styles/form.scss";
@import "assets/styles/swiper.scss";
@import "assets/styles/file-upload.scss";
@import "icons/style.scss";
@import "assets/styles/fonts";

body {
  overflow: auto;
  margin: 0;
  width: 100vw !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Inter", "Oxygen", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  touch-action: pan-x pan-y;
  height: 100%;
}

.pointer {
  cursor: pointer;
}

a {
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: color-opacity(var(--color-black), 0.7);
  }
}

hr {
  opacity: 1;
}

.overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &.two {
    -webkit-line-clamp: 2;
  }
  &.seven {
    -webkit-line-clamp: 7;
  }
}

.divider {
  background-color: var(--color-divider);
  width: calc(100% + 32px);
  height: 8px;
  margin-top: 8px;
  margin-left: -16px;
  border: none;
  &.mobile {
    display: none;
    @include isMobile {
      display: block;
    }
  }
}

.ant-picker-time-panel-column {
  overflow-y: scroll !important;
}
.ant-picker-dropdown .ant-picker-time-panel-column {
  width: 80px !important;
  text-align: center !important;
}
.ant-picker-dropdown .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  width: 72px !important;
  margin: 0px !important;
  padding-inline-start: 0px !important;
}
.ant-breadcrumb {
  ol {
    align-items: baseline;
  }
  li {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }
}
.ant-breadcrumb-link {
  cursor: pointer;
  background: var(--color-gray-50);
  color: black !important;
  padding: 8px;
  border-radius: 8px;
}

.w-max-content {
  width: max-content;
}
