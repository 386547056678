#form-file-upload {
  width: 100%;
  min-width: 180px;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 8px;
  padding: 24px;
  border-style: dashed;
  border-color: var(--color-gray-100);
  background-color: var(--color-gray-50);
}

#label-file-upload.drag-active {
  background-color: var(--color-gray-100);
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
  &:hover {
    text-decoration-line: underline;
  }
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
