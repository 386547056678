@import "/src/assets/styles/mixins.scss";

.modal {
  --bs-modal-border-width: 0;
  --bs-modal-border-radius: 16px;
  --bs-modal-padding: 24px 24px 40px;
  --bs-modal-width: 496px;
  &-lg {
    --bs-modal-width: 496px;
  }
  &-body {
    position: relative;
    padding: 40px 32px 32px;
  }
  &-remove {
    position: absolute;
    top: 16px;
    right: 16px;
    margin-bottom: 0;
  }
  &-header-text {
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    color: var(--color-black);
  }
  &-remove {
    cursor: pointer;
    background-color: var(--color-bg-light);
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include isMobile {
    &-content {
      width: 100%;
    }
    &-body {
      padding: 24px;
    }
    &-bottom {
      --bs-modal-width: 100%;
      --bs-modal-margin: auto 0 0 0;
      --bs-modal-border-width: 0;
      --bs-modal-border-radius: 12px 12px 0px 0px;
      --bs-modal-padding: 24px;
      .modal-dialog {
        width: 100%;
        position: fixed;
        bottom: 0;
      }
    }
  }
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 50px);
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-bottom.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 100%);
}
.modal-bottom.show .modal-dialog {
  transform: none;
}
