.set-page {
  gap: 40px;
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    justify-content: space-between;

    &-left {
      gap: 12px;
      display: flex;
      align-items: center;

      .set-name {
        font-size: 28px;
        font-weight: 700;
      }

      .delete-set {
        margin-left: auto;
      }
    }

    &-right {
      gap: 16px;
      display: flex;
      align-items: start;

      .delete-set {
        height: fit-content;
      }

      .add-to-basket {
        background: var(--color-primary);
      }

      .add-to-basket:disabled {
        background: var(--color-gray-100);
        color: var(--color-black);
      }

      &-add-to-cart {
        gap: 16px;
        display: flex;
        flex-direction: column;
        align-items: end;
      }
    }

    .overall-price {
      font-size: 15px;
      color: var(--color-gray-400);
    }
  }
}

.delete-set-btns {
  gap: 8px;
  display: flex;
  flex-direction: column;

  button {
    padding: 16px 24px;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 500;
  }

  &-confirm {
    background: var(--color-red);
  }

  &-reject {
    background: var(--color-gray-100);
  }
}

.favorite-list-empty {
  gap: 10px;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .set-page {
    &-header {
      margin-bottom: 24px;
      gap: 24px;
      display: flex;
      flex-direction: column;

      &-left {
        gap: 12px;
        display: flex;
        align-items: center;

        .set-name {
          font-size: 20px;
          font-weight: 700;
        }
      }

      &-right {
        &-add-to-cart {
          display: block;
        }

        .basket-block {
          position: fixed;
          z-index: 1;
          bottom: 60px;
          left: 0;
          right: 0;
          padding: 16px 24px;
          border-radius: 0 0 12px 12px;
          background: var(--color-white);
          box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);

          .add-to-basket {
            width: 100%;
          }
        }
        .overall-price {
          font-size: 14px;
        }
      }
    }
  }
}
