.lang-switcher {
  display: flex;
  &.filled {
    background: var(--color-gray-100);
    border-radius: 8px;
    padding: 4px;
    height: 40px;
    span {
      padding: 5px 16px;
    }
  }
  span {
    padding: 8px 16px;
    border-radius: 8px;
  }
  .bordered {
    border-bottom: 2px solid var(--color-primary);
    border-radius: 0;
  }
}

@media screen and (max-width: 1024px) {
  .lang-switcher {
  }
}
