@import "/src/assets/styles/mixins.scss";

.layout-auth {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    &-header {
        display: flex;
        width: 100%;
        @include isMobile {
            display: none;
        }
    }
    &-outlet {
        width: 440px;
        padding: 32px;
        margin-bottom: 0px;
        border: 1px solid #f7f8fa;
        border-radius: 16px;
        box-shadow: 0px 0px 24px 0px #00000014;
        &-wrapper {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            @include isMobile {
                align-items: flex-start;
            }
        }

        @include isMobile {
            width: 100%;
            max-width: unset;
            padding: 24px 16px;
            border: none;
            box-shadow: none;
        }
    }
    @include isMobile {
        align-items: flex-start;
    }
}
