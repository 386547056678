@import "/src/assets/styles/mixins.scss";

.events {
  &-card {
    color: var(--color-var-white);
    border-radius: 12px;
    padding: 16px;
    background: var(--color-black);

    &-badge {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 6px 8px;
      cursor: pointer;
      background: var(--color-primary-8);
      color: var(--color-primary);
      border-radius: 12px;
      backdrop-filter: blur(4px);
      &.dark {
        background: linear-gradient(0deg, rgba(50, 217, 217, 0.2) 0%, rgba(50, 217, 217, 0.2) 100%), #151324;
      }
    }

    &-btn {
      background: var(--color-white) !important;
      padding: 16px 32px;
      width: 100%;
      font-weight: 500;
      font-size: 13px;
      gap: 4px;
    }
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
    }
    &-avatar {
      object-fit: cover;
      border-radius: 50%;
      width: 64px;
      height: 64px;
    }

    &.past {
      .events-card-badge {
        background: rgba(#fff, 0.2);
        color: var(--color-white);
      }
    }
  }

  &-list {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    @include isMobile {
      gap: 12px;
      grid-template-columns: 1fr;
    }
  }
}
