@import "assets/styles/mixins.scss";

.product__item {
  display: flex;
  flex-direction: column;
  min-width: 140px;
  width: 100%;
  gap: 12px;
  &__image {
    position: relative;
    width: 100%;
    display: flex;
    height: 176px;
    background: var(--color-gray-50);
    border-radius: 12px;

    &__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .like {
      position: absolute;
      top: 12px;
      right: 10px;
      font-size: 20px;
    }
    .bonus {
      position: absolute;
      top: 7.5px;
      left: 9px;
    }
    &__content {
      object-fit: contain;
      height: 100px;
      width: calc(100% - 40px);
    }
  }
  &__badges {
    position: absolute;
    bottom: 7px;
    left: 9px;
  }
  &__badge {
    padding: 5px 8px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
    text-transform: capitalize;

    &.kit {
      background: var(--color-black);
      color: var(--color-white);
    }
    &.discount {
      background: var(--color-red);
      color: var(--color-white);
    }
    &.new {
      background: var(--color-primary);
    }
  }
}
