@import "/src/assets/styles/mixins.scss";

.layout-basket {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-header {
        display: flex;
        padding: 16px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 96px;
        border-bottom: 1px solid var(--color-gray-100);
        &-back {
            display: flex;
            gap: 8px;
            align-items: center;
            color: var(--color-gray-400);
            cursor: pointer;

            @include isMobile {
                display: none;
            }
        }
        &-name {
            display: none;
            @include isMobile {
                display: flex;
                gap: 8px;
                align-items: center;
                cursor: pointer;
            }
        }
        @include isMobile {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            background-color: var(--color-white);
            height: auto;
            box-shadow: none;
        }
    }
    &-outlet {
        padding: 32px;
        margin-bottom: 0px;
        border-radius: 16px;

        &-wrapper {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            @include isMobile {
                align-items: flex-start;
            }
        }

        @include isMobile {
            width: 100%;
            max-width: unset;
            padding: 24px 16px;
            border: none;
        }
    }
    @include isMobile {
        align-items: flex-start;
    }
}
