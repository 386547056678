.form-control {
    padding: 13px 16px;
    transition: 0.2s all;
    font-size: 14px;
    color: var(--color-black);
    background-color: var(--color-white);
    resize: none;
    border-radius: 12px;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    min-height: 50px;
    border: 1px solid var(--color-gray-100);
   
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type="number"] {
        -moz-appearance: textfield;
    }
    &-group {
        position: relative;
        &-icon {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &::placeholder {
        color: var(--color-gray-300);
    }
    &:hover {
        border-color: var(--color-primary);
    }
    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
        border-color: var(--color-primary);
    }
    &.invalid {
        border: 1px solid var(--color-red);
        &::placeholder {
            color: var(--color-red);
        }
    }
    &:disabled {
        background-color: var(--color-gray-100) !important;
        color: var(--color-gray-400) !important;
    }
}

.form-check-input {
    width: 1.5em;
    height: 1.5em;
    outline: 0 !important;
    box-shadow: none !important;
    &:checked {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
    }
}

.input-group {
    position: relative;
    > .form-control:nth-child(2) {
        border-top-left-radius: 12px !important;
        border-bottom-left-radius: 12px !important;
        padding-left: 40px;
    }
    &-icon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        z-index: 10;
    }
    &-append,
    &-prepend {
        display: flex;
        align-items: center;
        padding: 13px 16px;
        border-radius: 12px;
        color: var(--color-black);
        border: 1px solid var(--color-gray-200);
        background-color: var(--color-gray-100);
    }
}
