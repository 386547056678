.auth {
    &-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 24px 40px;
        gap: 24px;
        border-radius: 16px;
    }
    &-submit {
        padding: 12px 24px;
        background-color: var(--color-primary);
        width: 100%;
        color: var(--color-black) !important;
        font-weight: 500 !important;
        font-size: 15px;
        line-height: 18px;

        &:active,
        &:focus,
        &:focus-visible,
        &:hover {
            background-color: #2ccaca !important;
        }
        &:disabled {
            opacity: 0.3;
        }
    }
    &-register {
        padding: 12px 24px;
        background: var(--color-black);
        width: 100%;
        color: var(--color-white) !important;
        font-weight: 500 !important;
        font-size: 15px;
        line-height: 18px;

        &:active,
        &:focus,
        &:focus-visible,
        &:hover {
            background: var(--color-black) !important;
        }
        &:disabled {
            opacity: 0.3;
        }
    }
}

.phone-wrapper,
.otp-wrapper {
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.phone-errored > input {
    border-color: #eb5555 !important;
}
.phone-errored > .btn.disabled,
.phone-disabled > .btn.disabled {
    background-color: var(--color-primary);
}
.phone-error,
.otp-error {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #eb5555;
}
.sent-text {
    font-size: 14px;
    line-height: 140%;
    color: #868688;
}
.sent-number {
    font-weight: 500;
    color: var(--color-black);
}
.send-again-counter-text {
    text-align: center;
    font-size: 14px;

    line-height: 16px;
    padding: 16px 24px;
    width: 100%;
    border-radius: 12px;
    background: #f7f8fa;
    color: var(--color-black);
}
.send-again-counter {
    font-weight: 500;
    color: var(--color-black);
}
.send-again-text {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: var(--color-black);
    cursor: pointer;
}
.success-img-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.success-img-container > img {
    position: absolute;
    z-index: 1;
}
.favorite-icon {
    color: var(--color-primary);
    width: 48px;
    height: 48px;
    border-radius: 12px;
    background: rgba(#32d9d926, 15%);
    display: flex;
    justify-content: center;
    align-items: center;
}
