:root {
    --bs-link-color: var(--color-primary);
    --swiper-navigation-color: var(--color-white);
    --swiper-theme-color: var(--color-white);
    --swiper-pagination-color: var(--color-primary);
    --swiper-pagination-bullet-inactive-color: var(--color-gray-200);
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-width: 6px;
    --swiper-pagination-bullet-height: 6px;
}
