@import "/src/assets/styles/mixins.scss";

.visit {
  &-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &-title {
      font-weight: 700;

      line-height: 32px;
      font-size: 30px;
    }
  }
  &-form {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    &-item {
      display: flex;
      flex-direction: column;
      gap: 12px;

      font-weight: 400;
      font-size: 13px;
    }
  }
  @include isMobile {
    &-wrapper {
      box-shadow: unset;
      margin-bottom: 0;
      margin-top: 0;
      padding: 0;
      border: none;
    }
    &-header {
      gap: 12px;
      &-title {
        font-size: 24px;
      }
    }
  }
}
