@import "assets/styles/mixins.scss";

.bonus {
  &-wrapper {
    display: flex;
    flex-direction: row;
    gap: 64px;

    &-left {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      @include isMobile {
        & > .bonus-faq {
          display: none;
        }
      }
    }
    &-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      & > .bonus-faq {
        display: none;
      }
      @include isMobile {
        & > .bonus-faq {
          display: flex;
        }
      }
    }
    @include isMobile {
      flex-direction: column;
      gap: 16px;
    }
  }

  &-card {
    border: 1px solid var(--color-gray-50);
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
  }

  &-promo {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-plan {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &-current {
      display: flex;
      gap: 12px;
      align-items: start;

      .icon {
        min-width: 48px;
        width: 48px;
        height: 48px;
      }
    }
    &-passed {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
    &-option {
      flex: 1;
      font-size: 15px;
      border-radius: 8px;
      padding: 12px;
      color: var(--color-white);
      background: rgba(255, 255, 255, 0.2);
    }
  }

  &-faq {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .yume-chevron-bottom {
      transition: 0.25s all;
    }
    & > .bonus-card {
      padding: 20px;
      .bonus-faq-answer {
        display: none;
        transition: 0.25s all;
      }
      &.active {
        .yume-chevron-bottom {
          transform: rotate(180deg);
        }
        .bonus-faq-answer {
          display: inherit;
          animation: down-animation 0.25s ease-in-out;
        }
      }
    }
  }

  &-subscription {
    background-color: var(--color-black);
    color: var(--color-white);
  }

  &-history {
    width: calc(100% + 64px);
    margin-top: -40px;
    margin-bottom: -32px;
    margin-left: -32px;
    max-height: 80vh;
    overflow-y: scroll;
    padding: 48px 32px;

    & > .bonus-card {
      padding: 20px;

      .bonus-card-item {
        padding: 12px 0;
        border-bottom: 1px solid var(--color-gray-100);
        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
        &:first-child {
          padding-top: 0;
        }
      }
    }

    @include isMobile {
      width: calc(100% + 48px);
      margin-top: -24px;
      margin-bottom: -24px;
      margin-left: -24px;
      padding: 48px 24px;
    }
  }

  &-form {
    @include isMobile {
      width: calc(100% + 48px);
      margin-top: -24px;
      margin-bottom: -24px;
      margin-left: -24px;
      padding: 48px 24px;
      max-height: 80vh;
      overflow-y: scroll;
    }
    &-card {
      border: 1px solid var(--color-gray-100);
      border-radius: 12px;
      padding: 20px;
      .checker {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 12px;
        border: 1px solid var(--color-gray-100);
        background-color: var(--color-gray-50);
        transition: 0.25s all;
        &.active {
          border: 8px solid var(--color-primary);
        }
      }
      &-section {
        display: flex;
        gap: 10px;
        padding: 12px 16px;
        border-radius: 8px;
        margin-top: 8px;
        &.silver {
          background-color: var(--color-primary-20);
        }
        &.gold {
          background-color: rgb(250, 184, 49, 0.2);
          & > .yume-bonus {
            color: #fab831 !important;
          }
        }
      }
    }
    .switcher {
      border-radius: 12px !important;
    }
    .switcher-btn {
      border-radius: 8px !important;
      padding: 16px !important;
      &.selected {
        color: var(--color-white) !important;
        background-color: var(--color-black) !important;
      }
    }
  }
}

@keyframes down-animation {
  0% {
    opacity: 0;
    transform: translateY(-4px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
