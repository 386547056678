.profile-wrapper {
  &-avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }
  &-card {
    border-radius: 12px;
    background-color: var(--color-white) !important;
    padding: 20px;
  }
  &-logout {
    width: 100%;
    justify-content: space-between;
    color: var(--color-red) !important;
  }
  &-bonus {
    display: flex;
    align-items: center;
    color: var(--color-white);
    background-color: var(--color-black);
    cursor: pointer;
    border-radius: 12px;
    padding: 20px 16px;
    width: 100%;
    gap: 16px;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      outline: 5px solid var(--color-primary-8);
      background-color: var(--color-primary);
      color: var(--color-black);
    }
    &-count {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: white;
    }
    &-info {
      font-size: 13px;
      line-height: 16px;
      color: white;
    }
  }
}
.address-field {
  padding: 16px 0;
  border-bottom: 1px solid var(--color-gray-100);
  &:last-child {
    border: none;
  }
  &-label {
    color: var(--color-gray-300);

    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
  }

  &-value {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
}
