.feedback-item {
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid #f7f8fa;
  color: var(--color-gray-500);
  background: var(--color-gray-50);
  font-size: 13px;
  line-height: normal;
  cursor: pointer;

  &.selected {
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
  }
}
